import Preact from 'preact/compat';
import { getAccountSdk } from './auth/schibsted-account';
import { Header } from '@vgno/navigation';

/*
import { hasAccess } from './utils/access';
const getAccessFn = async () => {
    if (await hasAccess()) {
        return {
            isPluss: true,
        };
    }

    return {
        isPluss: false,
    };
};
*/

export default async function init() {
    const accountSdk = await getAccountSdk();
    const containerElm = document.getElementById('vg-nav-container');
    Preact.hydrate(
        Preact.createElement(Header, {
            accountSdk,
        }),
        containerElm,
    );
}
