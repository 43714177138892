/**
 * @callback onVisibilityChange
 * @param {{ element: HTMLElement; visible: IntersectionObserverEntry['isIntersecting'] }} state
 */

/**
 * Track changes to visibility of given elements. Visibility in this context
 * is defined as being in viewport.
 *
 * @param  {HTMLElement[]} elems  Elements whose visibility should be tracked
 * @param  {onVisibilityChange}   onVisibilityChange Function to call when visibility of an element changes.
 * @param  {IntersectionObserverInit} [opts] IntersectionObserver options
 * @return {IntersectionObserver} observer
 */
function trackVisibility(elems, onVisibilityChange, opts = {}) {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            onVisibilityChange({
                element: entry.target,
                visible: entry.isIntersecting,
            });
        });
    }, opts);

    elems.forEach((elm) => {
        observer.observe(elm);
    });

    return observer;
}

/**
 * @callback onViewCallback
 * @param {HTMLElement} element
 */

/**
 * @param {HTMLElement[]} elems
 * @param {onViewCallback} callback Callback to run once for each element in view
 * @param {IntersectionObserverInit} [opts] IntersectionObserver options
 * @returns {IntersectionObserver}
 */
export function onView(elems, callback, opts = {}) {
    const obs = trackVisibility(
        elems,
        (entry) => {
            if (entry.visible) {
                obs.unobserve(entry.element);
                callback(entry);
            }
        },
        opts,
    );
    return obs;
}

export default trackVisibility;
