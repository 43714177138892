import { isVGApp } from '../../utils/device';
import { hasSession } from '../../auth/schibsted-account';

const VALID_TYPES = ['bigImage', 'smallImage', 'fishfinger'];

async function trackPageView({ deferOnload }) {
    await deferOnload();

    const user = await hasSession();
    if (!user) {
        return;
    }

    const { userId } = user;
    const braze = await import('@braze/web-sdk');

    braze.initialize('d1240f31-a44b-4a02-9d5a-30677794528d', {
        baseUrl: 'sdk.fra-01.braze.eu',
        doNotLoadFontAwesome: true,
    });

    /** @type {HTMLElement[]} */
    const brazeContainers = $$('.braze-container');

    braze.changeUser(`sdrn:spid.no:user:${userId}`);

    braze.openSession();
    brazeContainers.forEach((brazeContainer) => {
        if (brazeContainer && userId) {
            braze.showContentCards(brazeContainer, function (cards) {
                const filteredCards = cards
                    .filter((card) => {
                        if (card.extras?.type !== 'front') {
                            return false;
                        }

                        if (isVGApp() && card.extras?.showApp === false) {
                            return false;
                        }
                        if (
                            card.extras?.position !==
                            brazeContainer.dataset.position
                        ) {
                            return false;
                        }

                        return true;
                    })
                    .slice(0, 1);

                /* This callback can run multiple times, so we check for both cases */
                if (filteredCards.length === 0) {
                    brazeContainer.hidden = true;
                    return;
                } else {
                    brazeContainer.hidden = false;
                }

                filteredCards.forEach((card) => {
                    if (VALID_TYPES.includes(card?.extras.type)) {
                        brazeContainer.dataset.type = card.extras.type;
                    }
                });

                /**
                 * Only return first card for now, until we know wether
                 * we should ever show more than one
                 */
                return filteredCards;
            });
        } else {
            return;
        }
    });
}

export default {
    trackPageView,
};
